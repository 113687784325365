<template>
  <section class="hero is-fullheight">
    <div class="hero-head">
      <the-header/>
    </div>
    <div class="hero-body">
      <the-sidebar/>
      <main>
        <router-view/>
      </main>
    </div>
  </section>
</template>

<script>
export default {
  name: 'home',
  components: {
    TheHeader: () => import('@/components/structure/TheHeader.vue'),
    TheSidebar: () => import('@/components/structure/TheSidebar.vue')
  }
}
</script>

<style scoped>
  .hero.is-fullheight .hero-body {
    padding: 0;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
  }

  main {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1023px) {
    .hero.is-fullheight .hero-body {
      flex-direction: column;
    }
  }
</style>
